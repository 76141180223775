<template>
  <div class="row">
    <div class="col-12 col-lg-5">
      <div class="card card-body">
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered">
            <tbody>
              <tr>
                <td>الإسم</td>
                <td>
                  {{ parent.name }}
                </td>
              </tr>
              <tr>
                <td>الهاتف</td>
                <td>
                  {{ parent.phone }}
                </td>
              </tr>
              <tr>
                <td>كلمة المرور</td>
                <td>
                  {{ parent.password }}
                </td>
              </tr>
              <tr>
                <td>ملاحظات</td>
                <td>
                  {{ parent.notes }}
                </td>
              </tr>
              <tr>
                <td>تاريخ الإضافة</td>
                <td>
                  {{ parent.date }}
                </td>
              </tr>
              <tr>
                <td>عدد الأبناء</td>
                <th>
                  {{ parent.students.length }}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-7">
      <div class="card card-body">
        <p>الأبناء</p>
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered custom-table">
            <thead>
              <th>الطالب</th>
              <th>رقم الهوية</th>
              <th>الصف</th>
              <th>الفصل</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <tr v-for="student in parent.students" :key="student._id">
                <td>{{ student.name }}</td>
                <td>{{ student.number }}</td>
                <td>{{ student.classname }}</td>
                <td>{{ student.classroom }}</td>
                <td>
                  <b-dropdown text="خيارات" variant="relief-primary" size="sm">
                    <b-dropdown-item
                      @click="$router.push('/students/overview/' + student._id)"
                    >
                      <i class="fa fa-user text-success"></i>
                      تفاصيل الطالب
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        $router.push('/students/add-or-edit#' + student._id)
                      "
                    >
                      <i class="fa fa-user-edit text-primary"></i>
                      تعديل الطالب
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      parent: { students: [] },
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    if (!checkPer("parents-view")) {
      this.$router.push("/per");
      return false;
    }
    var g = this;
    $.post(api + "/user/parents/parent-full", {
      jwt: g.user.jwt,
      id: window.location.href.split("/overview/")[1],
    }).then(function (e) {
      e = e.response;
      g.parent = e;
    });
  },
  methods: {
    checkPer(e) {
      return checkPer(e);
    },
  },
};
</script>

<style>
</style>